import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllAreaCodebyCarrierName } from "../service/getAllAreaCodebyCarrierName";
const initialState = {
  data: null,
  loading: false,
  error: null,
};


const getAllAreadCodeBycarrierName = createSlice({
  name: "getAllAreadCodeBycarrierName",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllAreaCodebyCarrierName.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllAreaCodebyCarrierName.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllAreaCodebyCarrierName.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllAreadCodeBycarrierName.reducer;

