import react, { useState } from 'react'
import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { Spinner } from '@chakra-ui/react'
import { AddCarrierService } from '../service/addCarrierService'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AddCarrier = () => {
    const {loading}=useSelector((state)=>state?.addcarrierslice)
    const [data, setData] = useState({
        carrier: "",
        price: "",
        status: "",
        name:""
    })
    const dispatch=useDispatch(

    )

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const navigate=useNavigate()
    const handleClick = async(e) => {
        e.preventDefault()
 await   dispatch(AddCarrierService(data)).unwrap()
   navigate('/numbersystem/OrderHistory')

        

    }
    return (
        <> <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
            <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Order History</h3>
        </div>
            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%] max-[768px]:w-[90%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>


                        <h3 className='font-medium' style={{ color: '#8A8D56' }}>Information</h3>
                    </div>
                    <div className='p-4'>
                    <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Name</FormLabel>
                              <Input name='name' placeholder='name' value={data?.name} onChange={handleChange}/>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Carrier</FormLabel>
                                <Select placeholder='   Carrier' name='carrier' onChange={handleChange}>
                                    <option value={'1'}>T-Mobile</option>
                                    <option value={'2'}>Varizon</option>
                                </Select>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Price</FormLabel>
                                <Input placeholder='Price' name='price' onChange={handleChange}>
                                 
                                </Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Status</FormLabel>
                                <Select onChange={handleChange} placeholder='Status' name='status'>
                                    <option value={'active'}>Active</option>
                                    <option value={'inactive'}> Inactive</option>
                                </Select>
                            </FormControl>

                        </div>
                        <button onClick={handleClick} style={{ background: '#8A8D56' }} class=" hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-3">
                          {
                            loading?(
                                <>
                                <Spinner/>
                                </>
                            ):(
                                <>
                            <AddIcon />  Create
                                
                                </>
                            )
                          }
                        </button>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCarrier