import React, { useState,useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from './auth/login';
import Header from './header/header';
import Dashboard from './dashboard/dashboard';
import OrderManagement from './dashboard/orderManagement';
import UserManagement from './dashboard/usermanagment';
import './App.css'
import OrderHistory from './dashboard/OrderHistory';
import OrderTracking from './dashboard/ordertracking';
import CreditManagement from './dashboard/creditmanagement';
import AddCarrier from './dashboard/addCarrier';
import AddCustomerPanel from './dashboard/addCustomer';
import AddNewClient from './dashboard/addNewClient';
import Shop from './dashboard/shop';
import OrderNumber from './dashboard/ordernumber';
import AddNewNumber from './dashboard/addnewNumber';
import CustomerPanel from './dashboard/customerpanel';
import AboutUs from './otherpages/aboutus';
import FaqSection from './otherpages/faq';
import TermsCondition from './otherpages/termscondition';
import PrivacyPolicy from './otherpages/privacyPolicy';
import ReturnExchange from './otherpages/returnExchange';
import Form from './otherpages/contactus';
import Updatenumber from './dashboard/updateNewNumber';
import { sucessToast } from './toast/toast';
import { emitter } from './dashboard/emiter';
import Contact from './dashboard/contact';
import { getCustomerbyIDservice } from './service/getCustomerdatabyIdService';
import { useDispatch, useSelector } from 'react-redux';
function App() {
  const customerId=localStorage.getItem('customerId')
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  useEffect(() => {
    if (customerId) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);
  // Function to handle login
  const handleLogin = () => {
    // Perform login logic, e.g., validate credentials
    // For demo purposes, directly setting login state to true
    setLogin(true);
    navigate('/numbersystem/customer/shop'); // Redirect to admin page after login
  };

  useEffect(() => {
    emitter.on("logout", () => {
      setLogin(false)
      navigate('/')
      // errorToast('')
      // navigate('/')
    });
  }, []);
  const dispatch=useDispatch()
   useEffect(()=>{
      dispatch(getCustomerbyIDservice())
   },[])
   const customerdata=useSelector((state)=>state?.getCustomerbyIdSlice?.data)
  console.log(customerdata)
   useEffect(()=>{
    if(customerdata?.acountStatus===false){
      localStorage.removeItem('customerId');
      setLogin(false);

  return navigate('/')
    }
  },[customerdata])




  return (
    <>
    {
     login && <Header/>
    }
      <Routes>
        {/* Route for Login */}

        {!login && <Route path="/" element={<Login onLogin={handleLogin} />} />}
        
        {/* Route for Header and Dashboard */}
        {login && (
          <>
          
            <Route path="/numbersystem/customer/dashboard" element={<Dashboard />} />
            <Route path="/numbersystem/customer/shop" element={<Shop />} />
            <Route path="/numbersystem/customer/OrderHistory" element={<OrderHistory />} />
            <Route path="/numbersystem/customer/userManagement" element={<UserManagement />} />
            <Route path="/numbersystem/customer/ordermanagement" element={<OrderManagement />} />
            <Route path="/numbersystem/customer/OrderTracking" element={<OrderTracking />} />
            <Route path="/numbersystem/customer/creditmanagement" element={<CreditManagement />} />
            <Route path="/numbersystem/customer/addCarrier" element={<AddCarrier />} />
            <Route path="/numbersystem/customer/AddCustomerPanel" element={<AddCustomerPanel />} />
            <Route path="/numbersystem/customer/AddNewClient" element={<AddNewClient />} />
            <Route path="/numbersystem/customer/OrderNumber" element={<OrderNumber />} />
            <Route path="/numbersystem/customer/AddNewNumber" element={<AddNewNumber />} />
            <Route path="/numbersystem/customer/CustomerPanel" element={<CustomerPanel />} />
            <Route path="/numbersystem/customer/AboutUs" element={<AboutUs />} />
            <Route path="/numbersystem/customer/Faq" element={<FaqSection />} />
            <Route path="/numbersystem/customer/termscondition" element={<TermsCondition />} />
            <Route path="/numbersystem/customer/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/numbersystem/customer/ReturnExchange" element={<ReturnExchange />} />
            <Route path="/numbersystem/customer/form" element={<Form />} />
            <Route path="/numbersystem/customer/contact" element={<Contact />} />











          </>
        )}

      </Routes>
    </>
  );
}

export default App;
