import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { GetDailyBasisService } from "../service/getDailybaisisDataService";
const initialState = {
  data: null,
  loading: false,
  error: null,
};


const getCustomerDailyabasisSlice = createSlice({
  name: "getCustomerDailyabasisSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(GetDailyBasisService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(GetDailyBasisService.fulfilled, (state, action) => {
      return { ...state,  loading: false ,data:action?.payload};
    });
    builder.addCase(GetDailyBasisService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getCustomerDailyabasisSlice.reducer;

