import react, { useEffect } from 'react'
import sim from '../assets/sim2.png'
import simcard from '../assets/simcard.png'
import { getShopDataService } from '../service/getShopDataService'
import { useDispatch, useSelector } from 'react-redux'
import p1 from '../assets/p1 (1).png'
import p2 from '../assets/p1 (2).png'
import p3 from '../assets/pinkp.png'
import p4 from '../assets/p1 (4).png'
import p5 from '../assets/p1 (5).png'
import p6 from '../assets/p1 (6).png'
import p7 from '../assets/p1 (7).png'
import { Link, useNavigate } from 'react-router-dom'
import { getAllCarrierService } from '../service/getallCarrierService'

const Shop = () => {
    const data = useSelector((state) => state?.getShopDatSlice?.data)
    const carrier = useSelector((state) => state?.getAllcarrierSlice?.data)

    console.log(data, 'data')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getShopDataService())
        dispatch(getAllCarrierService())

    }, [dispatch])
    const navigate = useNavigate()
    const handleNavigate = (value) => {
        navigate('/numbersystem/customer/AddNewNumber', { state: { selectedValue: value } });
    };


    const checkAvailableOrNot = (carrierName) => {
        const foundCarrier = carrier?.find(item => item?.name.toLowerCase() === carrierName.toLowerCase());

        return foundCarrier ? (foundCarrier.status === "Active" ? "Available" : "Returning Soon") : "Returning Soon";
    };


    return (
        <>

            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Shop</h3>
            </div>

            <div className='flex justify-center gap-3 mt-5'>

                <div className='grid grid-cols-2 max-[768px]:grid-cols-1 mx-auto gap-3 max-[768px]:w-[90%]'>

                    <div className={`p-5 rounded-md ${checkAvailableOrNot('Metro by T-Mobile') === "Available" ? ' cursor-pointer' : ''}`}    onClick={checkAvailableOrNot('Metro by T-Mobile') === 'Available' ? () => handleNavigate('Metro by T-Mobile') : null}  style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='flex justify-between items-center'>
                            <img src={p1} />
                            <div className=' p-2'>
                                <h3 className={`p-2 text-white ${checkAvailableOrNot('Metro by T-Mobile') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`} >
                                {checkAvailableOrNot('Metro by T-Mobile')}

                                </h3>
                            </div>
                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                Metro by T-Mobile Digital Port Numbers
                                To transfer to other carriers.<br />
                                These digital numbers are on the T-Mobile network and have to be transferred<br />
                                to other carriers outside of the T-Mobile network.
                            </p>

                        </div>
                    </div>

                    <div className={`p-5 rounded-md ${checkAvailableOrNot('Cricket Wireless') === "Available" ? ' cursor-pointer' : ''}`}    onClick={checkAvailableOrNot('Cricket Wireless') === 'Available' ? () => handleNavigate('Cricket Wireless') : null} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='flex justify-between items-center' >
                            <img src={p7} />
                            <div className={`p-2 text-white ${checkAvailableOrNot('Cricket Wireless') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`}>
                                <h3 className='text-white'>
                                {checkAvailableOrNot('Cricket Wireless')}
                                </h3>
                            </div>
                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                Cricket Digital Port Numbers
                                To transfer to other carriers. <br />
                                These digital numbers are on the Cricket Digital network and have to be transferred<br /> to other carriers outside of the Cricket Digital network.

                            </p>

                        </div>
                    </div>
                    <div className={`p-5 rounded-md ${checkAvailableOrNot('Verizon') === "Available" ? ' cursor-pointer' : ''}`} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
                        onClick={checkAvailableOrNot('Verizon') === 'Available' ? () => handleNavigate('Verizon') : null} // Navigate if available

                    >
                        <div className='flex justify-between items-center'>
                            <div>
                                <img src={p2} />
                            </div>
                            <div className={`p-2 ${checkAvailableOrNot('Verizon') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`}>
                                <h3 className='text-white cursor-pointer'>
                                    {checkAvailableOrNot('Verizon')}
                                </h3>
                            </div>
                          
                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                Verizon Digital Port Numbers
                                To transfer to other carriers.<br />
                                These digital numbers are on the Verizon network and have to be transferred<br />to other carriers outside of the Verizon network.

                            </p>

                        </div>
                    </div>
                    {/* onClick={()=>handleNavigate('T-Mobile')}  */}
                    <div  className={`p-5 rounded-md ${checkAvailableOrNot('T-Mobile') === "Available" ? ' cursor-pointer' : ''}`}
                        onClick={checkAvailableOrNot('T-Mobile') === 'Available' ? () => handleNavigate('T-Mobile') : null} // Navigate if available
                    
                    style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='flex justify-between items-center'>
                            <img src={p3} className='h-24 w-[9rem]' />
                            <div className={`p-2 ${checkAvailableOrNot('T-Mobile') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`}>
                                <h3 className='text-white'>
                                {checkAvailableOrNot('T-Mobile')}
                                </h3>
                            </div>
                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                T-Mobile Digital Port Numbers
                                To transfer to other carriers.<br />
                                These digital numbers are on the T-Mobile network and have to be transferred<br />
                                to other carriers outside of the T-Mobile network.
                            </p>

                        </div>
                    </div>
                    {/* onClick={()=>handleNavigate('Boost Mobile')} */}
                    <div  className={`p-5 rounded-md ${checkAvailableOrNot('Boost Mobile') === "Available" ? ' cursor-pointer' : ''}`}
                        onClick={checkAvailableOrNot('Boost Mobile') === 'Available' ? () => handleNavigate('Boost Mobile') : null}  style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='flex justify-between items-center'>
                            <img src={p4} />
                            <div className={`p-2 ${checkAvailableOrNot('Boost Mobile') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`}>
                                <h3 className='text-white '>
                                    {checkAvailableOrNot('Boost Mobile')}
                                </h3>
                            </div>

                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                Boost Digital Port Numbers
                                To transfer to other carriers.<br />
                                These digital numbers are on the AT&T and T-Mobile network and have to be transferred<br />to other carriers outside of the AT&T and T-Mobile network.

                            </p>

                        </div>
                    </div>
                    {/* onClick={()=>handleNavigate('AT&T')} */}
                    <div className={`p-5 rounded-md ${checkAvailableOrNot('AT&T') === "Available" ? ' cursor-pointer' : ''}`}
                        onClick={checkAvailableOrNot('AT&T') === 'Available' ? () => handleNavigate('AT&T') : null} style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='flex justify-between items-center'>
                            <img src={p5} />
                            <div className={`p-2 ${checkAvailableOrNot('AT&T') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`}>
                                <h3 className='text-white '>
                                    {checkAvailableOrNot('AT&T')}
                                </h3>
                            </div>

                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                AT&T Digital Port Numbers
                                To transfer to other carriers.<br />
                                These digital numbers are on the AT&T network and have to be transferred<br />
                                to other carriers outside of the AT&T network.

                            </p>

                        </div>
                    </div>
                    {/* onClick={()=>handleNavigate('UScellular')} */}
                    <div className={`p-5 rounded-md ${checkAvailableOrNot('UScellular') === "Available" ? ' cursor-pointer' : ''}`}
                        onClick={checkAvailableOrNot('UScellular') === 'Available' ? () => handleNavigate('UScellular') : null}style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                        <div className='flex justify-between items-center'
                        >
                            <img src={p6} />
                            <div className={`p-2 ${checkAvailableOrNot('UScellular') === "Available" ? 'bg-[green] cursor-pointer' : 'bg-[red]'}`}>
                                <h3 className='text-white '>
                                    {checkAvailableOrNot('UScellular')}
                                </h3>
                            </div>

                        </div>
                        <div>
                            <p className='text-slate-500 leading-5' style={{ fontSize: '12px',fontWeight:'700',color:'black' }}>
                                UScelular Digital Port Numbers
                                To transfer to other carriers. <br />
                                These digital numbers are on the USCelular network and have to be transferred<br /> to other carriers outside of the UScelular network.

                            </p>

                        </div>
                    </div>





                </div>
            </div>
        </>
    )
}

export default Shop