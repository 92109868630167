import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the components needed for the chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to generate month labels from February to the current month
const getMonthLabels = () => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 
    'August', 'September', 'October', 'November', 'December'
  ];
  const now = new Date();
  const currentMonth = now.getMonth(); // Current month (0-indexed)
  return monthNames.slice(1, currentMonth + 1); 
};

// Function to aggregate data by carrier name and month
const aggregateCarrierOrders = (datas, labels) => {
    console.log(datas,'s')

  const carrierOrders = {};

  // Initialize carrierOrders structure with carrier names and months
  datas?.forEach(monthData => {
    monthData?.carriers?.forEach(carrier => {
      if (!carrierOrders[carrier.carrierName]) {
        carrierOrders[carrier.carrierName] = Array(labels.length).fill(0);
      }

      const monthIndex = labels?.findIndex(label => label?.toLowerCase() === monthData?.month?.toLowerCase());
      if (monthIndex >= 0) {
        carrierOrders[carrier.carrierName][monthIndex] += carrier.quantity;
      }
    });
  });

  return carrierOrders;
};

const MyBarChart = ({ datas }) => {
  // Get labels from February to the current month
  const labels = getMonthLabels();

  // Aggregate orders by carrier name and month
  const carrierOrders = aggregateCarrierOrders(datas, labels);

  // Prepare datasets for each carrier
  const datasets = Object.keys(carrierOrders).map(carrierName => ({
    label: carrierName,
    data: carrierOrders[carrierName],
    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
    borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    borderWidth: 1,
  }));

  // Chart data and options
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      x: {
        stacked: true, // Stack the bars on the x-axis
      },
      y: {
        beginAtZero: true,
        stacked: true, // Stack the bars on the y-axis
      },
    },
    plugins: {
      legend: {
        position: 'top', // Position the legend at the top
      },
    },
  };

  return (
      <div style={{width:'70%',margin:'auto',height: '300px'}}>
      <div className="flex mt-5 ">
      <h3 className="font-medium text-2xl" style={{marginTop:'30px'}}>
Your Monthly Purchased Details

</h3>
        </div>
      <Bar data={data} options={options} />
      </div>
  );
};

export default MyBarChart;
