import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from '../handleError/handleError';
import { baseUrl } from '../baseUrl/baseUrl';
import { basicAuth } from '../baseUrl/basicAuth';
export const getAllAreaCodebyCarrierName = createAsyncThunk(
  "getAllAreaCodebyCarrierName",
  async (id) => {
    console.log(id)
    try {
      const url = baseUrl+ `getAllAvailableCarrierByName/${id}`
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning": "skip-browser-warning",
        }
      };

      const res = await axios.get(url, config); // Pass the config object as the second parameter
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
