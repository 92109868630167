import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';

// Reducer Imports
import rootReducer from './reducer/combinereducer';

// Initializing State
const initialState = {};

const middleware = [thunk];

const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

export default store;