import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from '../handleError/handleError';
import { baseUrl } from '../baseUrl/baseUrl';
import { basicAuth } from '../baseUrl/basicAuth';
export const getAllCarrierService = createAsyncThunk(
  "getAllCarrierService",
  async (id) => {
    try {
      const adminIdcustomer = localStorage.getItem('adminIdcustomer');
      const distributor = localStorage.getItem('distributorId');
      const url = `${baseUrl}getAllCarriers` 
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning": "skip-browser-warning",
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };

      const res = await axios.get(url, config); // Pass the config object as the second parameter
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
