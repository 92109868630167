import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllPricebyCarrierName } from "../service/getCarrirerPriceByCarrierName";

const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};


const getAllPricebycustId = createSlice({
  name: "getAllPricebycustId",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllPricebyCarrierName.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllPricebyCarrierName.fulfilled, (state, action) => {
      return { ...state,  loading: false ,data:action?.payload};
    });
    builder.addCase(getAllPricebyCarrierName.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllPricebycustId.reducer;

