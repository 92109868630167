import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicOth } from "../baseUrl/baseUrl";

import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";
import { sucessToast } from "../toast/toast";

export const ContactusService = createAsyncThunk(
    "ContactusService",
    async (payload) => {
        const customerId = localStorage.getItem('customerId')

        const data = {
            "customerEmail": payload?.email,
            "customerName": payload?.name,
            "customerId": customerId,
            "phoneNumber": payload?.number,
            "customerMessage": payload?.message
        }
        try {
            let url = baseUrl + `sendHtmlEmail`;
            const config = {
                headers: {
                    "Authorization": basicAuth, // Include basic authentication header
                    "ngrok-skip-browser-warning": "skip-browser-warning",
                    'Content-Type': 'application/json' // Set content type to application/json
                }
            };
            const res = await axios.post(url, data, config); // Pass the config object as the third parameter
            sucessToast(res.data)

            return res.data;
        } catch (error) {
            console.log(error)
            handleError(error); // Pass the entire error object to the handleError function
            throw error;
        }
    }
);
