import React, { useEffect } from "react";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';
import LineChartComponent from "./chart2";
import { useDispatch, useSelector } from "react-redux";
import { getAllDashboardServiceMonthly } from "../service/getDasboardDataMonthlyService";
import MyBarChart from "./mybarchat";
import { GetDailyBasisService } from "../service/getDailybaisisDataService";
import WeekChart from "./weekChart";

const Dashboard=()=>{
const data=useSelector((state)=>state?.getDashboardSliceMonthly?.data)
const weekdata=useSelector((state)=>state?.getCustomerDailyDataSlice?.data)
console.log(weekdata,'sjsjdjdj')

const dispatch=useDispatch()
useEffect(()=>{
  dispatch(getAllDashboardServiceMonthly())
  dispatch(GetDailyBasisService())
},[dispatch])
 console.log(data,'datadatadatadata')
    return(
        <>
      <WeekChart weekdata={weekdata} />

        
        <MyBarChart datas={data}/>
     

        </>
    )
}

 export default Dashboard