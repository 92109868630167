import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { AddOrderNumberService } from "../service/addOrderNumberService";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const AddOrderNumberSlice = createSlice({
  name: "AuthSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AddOrderNumberService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(AddOrderNumberService.fulfilled, (state, action) => {
        // sucessToast("Order Created Sucessfully");
      return { ...state,  loading: false };
    });
    builder.addCase(AddOrderNumberService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});


export default AddOrderNumberSlice.reducer;

