import react from 'react'
import { FormControl, FormLabel, Select } from '@chakra-ui/react'

import { PhoneIcon, AddIcon, WarningIcon, ViewIcon, MinusIcon } from '@chakra-ui/icons'
import view from '../assets/view.png'
import edit from '../assets/edit.png'
import exportpng from '../assets/export.png'
import { Button, Input } from "@chakra-ui/react";

const CreditManagement = () => {
    return (
        <>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto p-2 text-xl max-[768px]:text-center">Credit Management</h3>
            </div>

            <div className='flex mx-auto justify-center items-center h-full mt-4'>
                <div className='rounded-[5px] w-[60%]' style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
                    <div className='px-4 py-3 text-xl border-b-2' style={{ background: 'rgba(243, 251, 254, 1)' }}>
                        <h3 className='text-blue-500 font-medium'>Manage Credits</h3>
                    </div>
                    <div className='p-5'>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Client List</FormLabel>
                                <Select placeholder='Select Client or Distributor'>
                                    <option>United Arab Emirates</option>
                                    <option>Nigeria</option>
                                </Select>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Description</FormLabel>
                                <Input placeholder="Description"></Input>
                            </FormControl>

                        </div>
                        <div className=''>
                            <FormControl >
                                <FormLabel margin={'10px'} color={'grey'}>Amount</FormLabel>
                                <Input type='number' placeholder="Amount"></Input>
                            </FormControl>

                        </div>
                        <div>
                        </div>
                        <div className='flex gap-3 mt-2 mx-1'>
                        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                          <AddIcon/>  Add Credits
                        </button>
                        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          <MinusIcon/> Refund
                        </button>
                    </div>

                    </div>
                    
                </div>
            </div>




            <div className="">
                <div className="w-[98%]  max-[768px]:flex-wrap  mx-auto mt-4 flex gap-2 items-center justify-between mb-4 max-[768px]:justify-center max-[768px]:w-[94%] ">
                    <div className="w-[98%] mx-auto mt-4 flex gap-2 items-center mb-4 max-[768px]:justify-between max-[768px]:w-[94%]">
                        <div>
                            <p style={{ color: 'rgba(18, 168, 78, 1)' }} className="text-[rgba(18, 168, 78, 1)]">COLUMNS</p>
                        </div>
                        <div>
                            <Button>
                                <img src={exportpng} />
                                Export
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div class='max-w-md mx-auto max-[768px]:flex '>
                            <div style={{ border: '1px solid rgba(82, 87, 214, 1)' }} class="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                                <div class="grid place-items-center h-full w-12 text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>

                                <input
                                    class="peer h-full w-full outline-none text-sm text-gray-700 pr-2 "
                                    type="text"
                                    id="search"
                                    placeholder="Search something.." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <body class="w-full mx-auto bg-gray-100">
                <div class="w-full mx-auto mt-5 overflow-x-auto">
                    <div class="overflow-y-auto max-h-[50vh]">
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-300 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" class="px-6 py-3">Id</th>
                                    <th scope="col" class="px-6 py-3">Ref</th>
                                    <th scope="col" class="px-6 py-3">Description</th>
                                    <th scope="col" class="px-6 py-3">Amount</th>
                                    <th scope="col" class="px-6 py-3">Created At</th>
                                    <th scope="col" class="px-6 py-3">Dealer Email</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr class="border-b">
                                    <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">1</td>
                                    <td class="px-6 py-4">pawan</td>
                                    <td class="px-6 py-4">Description</td>
                                    <td class="px-6 py-4 text-red-500 font-medium">900  </td>
                                    <td class="px-6 py-4">7-72023   </td>

                                    <td class="px-6 py-4">UU@GMAIL.COM</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </body>

        </>
    )
}

export default CreditManagement