import { createSlice } from "@reduxjs/toolkit";
import { getShopDataService } from "../service/getShopDataService";
import { sucessToast } from "../toast/toast";
const initialState = {
  data: null,
  loading: false,
  error: null,
  classNotesList: [],
};

const getShopDataSlice = createSlice({
  name: "AuthSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getShopDataService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getShopDataService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getShopDataService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });

    
  },
});


export default getShopDataSlice.reducer;

