import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { basicOth } from "../baseUrl/baseUrl";

import { baseUrl } from "../baseUrl/baseUrl";
import { basicAuth } from "../baseUrl/basicAuth";
import { handleError } from "../handleError/handleError";
import { errorToast, sucessToast } from "../toast/toast";

export const AddOrderNumberService = createAsyncThunk(
  "GetApprovedSchoolService",
  async (temp) => {
    try {
    const parseData=JSON.parse(temp?.expDate)

      console.log(temp,'lkjv')
        const customerId=localStorage.getItem('customerId')
      let url = baseUrl + `addOrder`;

      const data = {
        passDistributer: null,
        passCustomer: {
          customerId: customerId
        },
        orderStatus: 'ACTIVE',
        "deliveryCharges":parseData?.valueA,
      "deliveryType":parseData?.valueB,
        carrierId:null,
        carrierName:temp?.carrier,
        areaCode: temp?.areacode,
        orderQuantity: temp?.quantity,
        totalCost: temp?.price,
        orderStatusDescription: temp?.desc,
        createdDate: null,
        updatedDate: null
      };
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          "ngrok-skip-browser-warning" : "skip-browser-warning",
          'Content-Type': 'application/json' // Set content type to application/json
        }
      };
      const res = await axios.post(url,data, config); // Pass the config object as the third parameter
       if(res.data==='Insufficient funds for Purchase'){
        errorToast('Insufficent fund for Purchase')
       }else{
        sucessToast('Order Created Successfully')
       }
      return res.data;
    } catch (error) {
      console.log(error)
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
