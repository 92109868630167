import { createSlice } from "@reduxjs/toolkit";

import { sucessToast } from "../toast/toast";
import { getAllCarrierService } from "../service/getallCarrierService";
const initialState = {
  data: null,
  loading: false,
  error: null,
};


const getAllcarrierSlice = createSlice({
  name: "geAllcarrierSLICE",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllCarrierService.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(getAllCarrierService.fulfilled, (state, action) => {
      return { ...state,  loading: false,data:action.payload };
    });
    builder.addCase(getAllCarrierService.rejected, (state, action) => {
      return { ...state, loading: false, error: "Something went wrong" };
    });
  },
});


export default getAllcarrierSlice.reducer;

