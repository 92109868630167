import { combineReducers } from 'redux'
import authslice from '../slice/authslice'
import getShopDatSlice from '../slice/getShopDatSlice'
import getAllOrderSlice from '../slice/getAllOrderSlice'
import { getOrderHistoryById } from '../service/getOrderHistoryById'
import updateCarrierSlice from '../slice/updateCarrierSlice'
import getAllNumberSlice from '../slice/getAllNumberSlice'
import addOrderNumberSlice from '../slice/addOrderNumberSlice'
import updateNumberSlice from '../slice/updateNumberSlice'
import getAllcarrierSlice from '../slice/getAllcarrierSlice'
import getCustomerbyIdSlice from '../slice/getCustomerbyIdSlice'
import getAllAreaCodeByCarrierName from '../slice/getAllAreaCodeByCarrierName'
import getallpricebycustomerIdSlice from '../slice/getallpricebycustomerIdSlice'
import getSimcardNumberSlice from '../slice/getSimcardNumberSlice'
import getDashboardSliceMonthly from '../slice/getDashboardSliceMonthly'
import getCustomerDailyDataSlice from '../slice/getCustomerDailyDataSlice'
import contactusSlice from '../slice/contactusSlice'
const rootReducer=combineReducers({
    authslice:authslice,
    getShopDatSlice:getShopDatSlice,
    getAllOrderSlice:getAllOrderSlice,
    getOrderHistoryById:getOrderHistoryById,
    updateCarrierSlice:updateCarrierSlice,
    getAllNumberSlice:getAllNumberSlice,
    addOrderNumberSlice:addOrderNumberSlice,
    updateNumberSlice:updateNumberSlice,
    getAllcarrierSlice:getAllcarrierSlice,
    getCustomerbyIdSlice:getCustomerbyIdSlice,
    getAllAreaCodeByCarrierName:getAllAreaCodeByCarrierName,
    getallpricebycustomerIdSlice:getallpricebycustomerIdSlice,
    getSimcardNumberSlice:getSimcardNumberSlice,
    getDashboardSliceMonthly:getDashboardSliceMonthly,
    getCustomerDailyDataSlice:getCustomerDailyDataSlice,
    contactusSlice:contactusSlice
})

export default rootReducer