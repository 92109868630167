import react from 'react'
const Form = () => {
    return (
        <>
            <style>
                {
                    `
                
                $teal:range;
$white:#ffffff;


.container {

	background-color: $white;
	border-radius: 5px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
  min-width:370px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin:auto;
}


.form-container {
  display:flex;
  flex-direction:row-reverse;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  
}

.left-container {
  flex:1;
  height:480px;
  background-color:$teal;
}
.right-container {
  display:flex;
  flex:1;
  height:460px;
  background-color: $white;
  justify-content:center;
  align-items:center;
}

.left-container {
  display:flex;
  flex:1;
  height:480px;
  justify-content:center;
  align-items:center;
    color:$white;
}

.left-container p {
  font-size:0.9rem;
}

.right-inner-container {
  width:70%;
  height:80%;
  text-align:center;
}

.left-inner-container {
  height:50%;
  width:80%;
  text-align:center;
  line-height:22px;
}

input, textarea {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
  font-size:0.8rem;
}

input:focus, textarea:focus{
  outline:1px solid $teal;
}



@media only screen and (max-width: 600px) {
 .form-container {
  display:flex;
  flex-direction:column;
  gap:20px;
  flex-wrap:wrap;

  
}
}

@media only screen and (min-width: 600px) {
  .sm-view {
    display:none;  
  }
}

form p {
  text-align:left;
}
                `
                }
            </style>
            <div className="mx-auto mt-6" style={{ background: 'rgba(243, 251, 254, 1)' }}>
                <h3 className="font-medium w-[98%] mx-auto text-xl max-[768px]:text-center">Contact Us</h3>
            </div>
            <div class="container mt-5">
                <div class="form-container">
                    <div class="left-container">
                        <div class="left-inner-container p-5" style={{ background: 'rgba(82, 87, 214, 1)' }}>
                            <h2 className='text-xl text-white font-medium'>Hours Of Operation</h2>
                            <p className='text-white mt-2' >Whether you have a question, want to start a project or            simply want to connect.</p>
                            <br />
                            <p className='text-white'>Feel free to send me a message in the contact form</p>
                        </div>
                    </div>
                    <div class="right-container mb-2" >
                        <div class="right-inner-container">
                            <form action="#">
                                <p>* Required</p>
                                <div class="social-container">
                                    <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
                                    <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
                                </div>
                                <input type="text" placeholder="Name *" />
                                <input type="email" placeholder="Email *" />
                                <input type="number" placeholder="Number" />
                                <input type="text" placeholder="subject" />
                                <textarea rows="4" placeholder="Message"></textarea>
                                <button class="bg-[rgb(82, 87, 214)] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" style={{background:'rgb(82, 87, 214)'}}>
                                    Submit
                                </button>		</form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Form