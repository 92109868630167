import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MenuItem, MenuList, MenuButton, Menu, Button, DrawerOverlay, DrawerCloseButton, DrawerContent, DrawerBody, DrawerHeader, Drawer, useDisclosure } from '@chakra-ui/react'
import logo from '../assets/logo2.png'
import { emitter } from '../dashboard/emiter'
import { useSelector } from 'react-redux'
import { AddIcon } from '@chakra-ui/icons'
const Header = () => {
  const data = useSelector((state) => state?.getShopDatSlice?.data)
  const buttonRef = useRef(null)

  const location = useLocation()

  useEffect(() => {
    const links = document.querySelectorAll('.item');
    links.forEach(link => {
      if (link.pathname === location.pathname) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }, [location]);





  const [size, setSize] = React.useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }

  const handleLogout = () => {
    localStorage.removeItem('customerId');
    localStorage.removeItem('adminIdcustomer');
    localStorage.removeItem('distributorId');
    emitter.emit("logout");
  }




  const [isOpen1, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);


  const toggleDropdown = () => {
    setIsOpen(!isOpen1);
  };
  const handleMouseDown = (event) => {
    if (dropdownRef.current && buttonRef.current) {
      const { top: dropdownTop, left: dropdownLeft, width: dropdownWidth, height: dropdownHeight } = dropdownRef.current.getBoundingClientRect();
      const { top: buttonTop, left: buttonLeft, width: buttonWidth, height: buttonHeight } = buttonRef.current.getBoundingClientRect();
      const { clientX: mouseX, clientY: mouseY } = event;

      // Calculate proximity distance to the dropdown and button
      const isNearDropdown =
        mouseX >= dropdownLeft - 10 &&
        mouseX <= dropdownLeft + dropdownWidth + 10 &&
        mouseY >= dropdownTop - 10 &&
        mouseY <= dropdownTop + dropdownHeight + 10;

      const isNearButton =
        mouseX >= buttonLeft - 10 &&
        mouseX <= buttonLeft + buttonWidth + 10 &&
        mouseY >= buttonTop - 10 &&
        mouseY <= buttonTop + buttonHeight + 10;

      // Close dropdown if the cursor is not near dropdown or button
      if (!isNearDropdown && !isNearButton) {
        setIsOpen(false);
      }
    }
  };
  useEffect(() => {
    if (isOpen1) {
      // Add event listener to track mouse movement
      document.addEventListener('mousemove', handleMouseDown);
    } else {
      // Remove event listener if dropdown is not open
      document.removeEventListener('mousemove', handleMouseDown);
    }

    // Clean up event listener when the component unmounts or when isOpen changes
    return () => {
      document.removeEventListener('mousemove', handleMouseDown);
    };
  }, [isOpen1]);

  return (
    <>
      <style>
        {
          `   
          th{
                background:#FBB04B !important;
                 }
                td{
               text-align:center !important; 
                }

            .drwaserdrawer h3{
          background:aliceblue;
          margin-top:10px;
          padding-left:20px;

          }
                .item.active{
                color:orange;
                border-bottom:1px solid orange;

                }
                .item:hover{
                color:#8A8D56;
                border-bottom:1px solid orange;
                }
                @media(max-width:768px){
                .rounded-\[5px\].w-\[60\%\] {
      width: 90%;
  }
                }
                `
        }
      </style>
      <div className='w-full max-[768px]:hidden'>
        <div className='flex justify-between w-[98%] mx-auto mt-4 flex-wrap'>
          <div className=''>
            <h3 className='font-[500]' style={{ width: '100px' }}>
              <Link to={'/numbersystem/customer/Dashboard'}>
                <img src={logo} />
              </Link>
            </h3>
          </div>

          <div className=''>
            <Link to='/numbersystem/customer/Dashboard' className='item'>
              <h3 className='font-[500]'>Dashboard</h3>
            </Link>

          </div>
          <div className=''>
            <Link to='/numbersystem/customer/shop' className='item'>
              <h3 className='font-[500]'>Shop</h3>
            </Link>

          </div>
          <div className=''>
            <Link to='/numbersystem/customer/OrderHistory' className='item'>
              <h3 className='font-[500]'>Past Orders </h3>
            </Link>
          </div>

          <div className=''>
            <Link to='/numbersystem/customer/OrderNumber' className='item'>
              <h3 className='font-[500]'>Order Number</h3>
            </Link>
          </div>

          <div className='' >

            <div className="relative inline-block text-left">
              <div>

                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4  text-sm font-medium t rounded-md  focus:outline-none"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={toggleDropdown}
                  ref={buttonRef} // Attach ref to the button

                >
                <h3 style={{fontSize:'16px'}}>  Menu</h3>

                </button>
              </div>

              {isOpen1 && (
                <div
                  className="absolute z-40 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                  ref={dropdownRef} // Attach ref to the dropdown menu
                >

                  <div className="py-1" role="none">
                    <Link
                      to="/numbersystem/customer/AboutUs"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onClose}

                    >
                      About Us
                    </Link>
                    <Link
                      to="/numbersystem/customer/faq"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onClose}

                    >
                      FAQ
                    </Link>
                    <Link
                      to="/numbersystem/customer/termscondition"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onClose}

                    >
                      Terms and Condition
                    </Link>
                    <Link
                      to="/numbersystem/customer/PrivacyPolicy"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onClose}

                    >
                      Privacy Policy
                    </Link>
                    <Link
                      to="/numbersystem/customer/ReturnExchange"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onClose}

                    >
                      Return & Exchange
                    </Link>

                    <Link
                      to="/numbersystem/customer/contact"
                      className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onClose}

                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=''>
            <h3 className='font-[500] cursor-pointer' onClick={handleLogout}>Logout</h3>
          </div>
          <h3 style={{ color: 'green' }}>
            ${data?.balance}
          </h3>
          <h3 className='font-bold text-slate-700'>

       Welcome   {localStorage.getItem('customername')}
          </h3>
        </div>
      </div>






      {/* mobile */}

      <div className='w-full bg-[aliceblue] p-3 block md:hidden '>
        <div className=' items-center  justify-between w-11/12 mx-auto ' style={{ display: 'flex' }}>
          <div>
            <Link to='/numbersystem/customer/shop'>
              <h3 className='font-[500]' style={{ width: '100px' }}>
                <img src={logo} />
              </h3>
            </Link>
          </div>

          <div>
            <Button
              onClick={() => handleClick('xs')}
              key={'xs'}
            >
              Menu
            </Button>
          </div>
        </div>
      </div>
      <Drawer onClose={onClose} isOpen={isOpen} size={size} className='hidden max-[768px]:block'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{localStorage.getItem('customername')}</DrawerHeader>
          <DrawerBody lineHeight={'45px'} padding={'0px'} className='drwaserdrawer'>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/shop' className='item'>
                <h3 className='font-[500]'>Shop</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/Dashboard' className='item'>
                <h3 className='font-[500]'>Dashboard</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/OrderHistory' className='item'>
                <h3 className='font-[500]'>Past Orders</h3>
              </Link>
            </div>


            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/OrderNumber' className='item'>
                <h3 className='font-[500]'>Order Number</h3>
              </Link>
            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/AboutUs' className='item'>
                <h3 className='font-[500]'>About Us</h3>
              </Link>
            </div>


            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/faq' className='item'>
                <h3 className='font-[500]'>FAQ</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/PrivacyPolicy' className='item'>
                <h3 className='font-[500]'>Privacy Policy</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/termscondition' className='item'>
                <h3 className='font-[500]'>Terms & Condition</h3>
              </Link>

            </div>
            <div className=''
              onClick={onClose}

            >
              <Link to='/numbersystem/customer/ReturnExchange' className='item'>
                <h3 className='font-[500]'>Return & Exchange</h3>
              </Link>
            </div>
            <div

            >
              <Link
                to="/numbersystem/customer/contact"
                className="item"
                role="menuitem"
                onClick={onClose}

              >
                <h3 className='font-[500]'>      Contact Us</h3>
              </Link>
            </div>
            <div className=''>
              <h3 className='font-[500] cursor-pointer' onClick={handleLogout}>Logout</h3>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>


      <div className="flex justify-end items-end mr-2">
                        <Link to={'/numbersystem/customer/AddNewNumber'}>
                            <button class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
                                <AddIcon w={5} h={3} boxSize={4} />     Order Numbers
                            </button>
                        </Link>
                    </div>

    </>
  )
}

export default Header