import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { handleError } from "../handleError/handleError";
import { baseUrl } from "../baseUrl/baseUrl";

import { basicAuth } from "../baseUrl/basicAuth";
// Function to format date to YYYY-MM-DD
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Get start date as February 1 of the current year
const getStartDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), 1, 1); // February is month 1 (zero-based)
};

// Get end date as current date
const getEndDate = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1); // Add 1 day to the current date
  return now;
};

export const getAllDashboardServiceMonthly = createAsyncThunk(
  "getAllDashboardServiceMonthly",
  async () => {
    try {
        const  customerId=localStorage.getItem('customerId')
      const startDate = formatDate(getStartDate());
      const endDate = formatDate(getEndDate());
      
      let url = `${baseUrl}customerMonthlyOrderData?customerId=${customerId}&startDate=${startDate}&endDate=${endDate}`;
      
      const config = {
        headers: {
          "Authorization": basicAuth, // Include basic authentication header
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning" : "skip-browser-warning",
        }
      };
      
      const res = await axios.get(url, config); // Pass the config object as the second parameter
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      handleError(error); // Pass the entire error object to the handleError function
      throw error;
    }
  }
);
